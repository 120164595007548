const AboutSection = () => {
  return (
    <div id="about" className="max-w-6xl mx-auto mb-72 mt-56 px-8">
      <h2 className="font-bold text-center sm:text-6xl text-4xl mb-7">
        Over $500M in Assets Under Our Care
      </h2>
      {/* <div className="subtitle text-center text-xl mb-7">
      "We do RWA in Real Estate"
      </div> */}

      {/* <div className="wrapper flex gap-5 md:gap-10 flex-col md:flex-row">
        <div className="flex-1">
          Welcome to Whale Capital, a leading AI software development company
          that delivers cutting-edge solutions to businesses worldwide. We
          specialize in developing custom AI software that helps businesses
          automate processes, enhance decision-making capabilities, and drive
          growth.
          <br></br>
          <br></br>
          At Whale Capital, we believe that AI is the future of business. Our
          team of expert developers, data scientists, and engineers is dedicated
          to helping our clients leverage the power of AI to achieve their
          business goals. Whether you are looking to optimize your operations,
          improve customer experience, or gain a competitive advantage, we can
          provide you with the right AI solutions tailored to your specific
          needs.
        </div>

        <div className="flex-1">
          We pride ourselves on our ability to stay at the forefront of the
          latest AI trends and technologies. Our team has a deep understanding
          of machine learning, natural language processing, computer vision, and
          other cutting-edge AI technologies. We use this expertise to build AI
          solutions that are efficient, scalable, and cost-effective.
          <br></br>
          <br></br>
          At Whale Capital, we value our clients and are committed to providing
          exceptional customer service. We work closely with our clients to
          understand their needs, goals, and challenges, and we tailor our AI
          solutions accordingly. Our goal is to help our clients succeed by
          delivering AI solutions that are easy to use, reliable, and effective.
        </div>
      </div> */}
    </div>
  );
};

export default AboutSection;
